.richtext {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 0.75em 0;
        color: $black;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5,
    * + h6 {
        margin-top: 1.5em;
    }

    h2,
    h3 {
        font-weight: 600;
    }

    h2 {
        font-size: 24px;
        
        @include media-breakpoint-up(md) {
            font-size: 28px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 32px;
        }
    }

    h3 {
        font-size: 20px;
        
        @include media-breakpoint-up(md) {
            font-size: 22px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 24px;
        }
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        
        @include media-breakpoint-up(md) {
            font-size: 20px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 21px;
        }
    }

    p,
    ul,
    ol {
        margin: 0 0 1em 0;
        padding: 0;
        list-style: none;
        line-height: 1.7;
        color: $darkgrey;

        b {
            font-weight: 500;
            color: $black;
        }

        a {
            font-weight: 500;
            text-decoration: underline;
            color: $pink;
        }

        li {
            position: relative;
            margin-bottom: 0.75em;

            &::before {
                position: absolute;
                left: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul {

        li {
            padding-left: 40px;

            &::before {
                content: '';
                top: 0.75em;
                width: 20px;
                height: 1px;
                background: $pink;
            }
        }
    }

    ol {
        counter-reset: ordered-list-counter;

        li {
            padding-left: 40px;
            counter-increment: ordered-list-counter;

            &::before {
                content: counter(ordered-list-counter) ".";
                top: 0;
                width: 40px;
                font-weight: 500;
                color: $black;
            }
        }
    }

    img {
        display: block;
        width: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.06);
    }

    figure {
        margin: 0;

        figcaption {
            font-size: 12px;
            color: $mediumgrey;

            a {
                color: $mediumgrey;
            }
        }

        img + figcaption {
            margin-top: 1em;
        }
    }

    * + img,
    * + figure,
    img + *,
    figure + * {
        margin-top: 32px;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    &.richtext-sm {

        p {
            font-size: 14px;
        }
    }

    &.richtext-lg {

        p {
            font-size: 18px;
        }
    }

    &.richtext-xl {

        p {
            font-size: 20px;
            color: $black;

            @include media-breakpoint-up(md) {
                font-size: 22px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 24px;
            }
        }
    }

    &.richtext-lead {

        p {

            &:first-child {
                font-weight: 500;
                font-size: 17px;
                line-height: 1.6;
                color: $black;

                @include media-breakpoint-up(md) {
                    font-size: 19px;
                    line-height: 1.55;
                }
    
                @include media-breakpoint-up(xl) {
                    font-size: 21px;
                    line-height: 1.5;
                }
            }
        }
    }
}

.bg-darkestgrey {
    
    .richtext {

        p {
            color: $lightgrey;

            b {
                color: $white;
            }
        }
    }
}