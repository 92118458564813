.site-menu {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 5vw;
    background-color: rgba(147,145,154,0.5);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.4s, visibility 0.4s;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        backdrop-filter: blur(20px);
    }

    @include media-breakpoint-up(md) {
        display: none;
    }

    .site-menu-main {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 100%;
        overflow: hidden;
        margin: auto;
        border-radius: 10px;
        background: $white;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.4s, transform 0.4s;

        .site-menu-close {
            position: absolute;
            right: 16px;
            top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            padding: 0;
            color: $black;
            border-radius: 50%;
            border: none;
            background: $lightestgrey;

            ion-icon {
                font-size: 24px;
            }
        }

        .site-menu-nav {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 64px 5vw 32px 5vw;
            font-weight: 600;
            font-size: 24px;
            text-align: center;

            @include media-breakpoint-up(sm) {
                font-size: 28px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    
                    a {
                        display: inline-block;
                        line-height: 2;
                        color: $black;
                    }
                }
            }
        }

        .site-menu-social {
            display: flex;
            border-top: 1px solid rgba(0,0,0,0.06);

            a {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                color: $mediumgrey;
                border-right: 1px solid rgba(0,0,0,0.06);

                &:last-child {
                    border: none;
                }

                ion-icon {
                    font-size: 24px;
                }
            }
        }
    }

    &.site-menu-is-open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transition: opacity 0.4s, visibility 0.4s;

        .site-menu-main {
            opacity: 1;
            transform: translateY(0);
        }
    }
}