.hero {
    position: relative;
    padding-top: 64px;
    color: $white;
    background: $black;
    background-image: url('../img/interface/hero-bg.jpg');
    background-position: center;
    background-size: cover;

    @include media-breakpoint-up(md) {
        padding-top: 72px;
    }

    .hero-container {
        position: relative;
        z-index: 2;
        padding: 96px 0;

        @include media-breakpoint-up(md) {
            padding: 112px 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 128px 0;
        }

        .richtext {
            
            p {
                color: rgba(255,255,255,0.8);
            }
        }
    }

    .hero-search {
        position: relative;

        input {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 100%;
            height: 48px;
            padding: 0 16px;
            font-weight: 300;
            color: $darkgrey;
            border-radius: 10px;
            border: none;
            background: $lightestgrey;
            box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
            appearance: none;
            outline: none;
            transition: color 0.2s, background-color 0.2s;

            @include media-breakpoint-up(md) {
                height: 52px;
                padding: 0 20px;
            }

            @include media-breakpoint-up(md) {
                height: 56px;
                padding: 0 24px;
            }

            &:focus {
                color: $black;
                border: none;
                background: $white;
            }
        }

        .hero-search-submit {
            position: absolute;
            z-index: 2;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            padding: 0;
            border: none;
            background: transparent;

            @include media-breakpoint-up(md) {
                width: 52px;
                height: 52px;
            }

            @include media-breakpoint-up(md) {
                width: 56px;
                height: 56px;
            }
            
            ion-icon {
                font-size: 22px;
            }
        }
    }

    .heading-2xl + .hero-search {
        margin-top: 36px;

        @include media-breakpoint-up(md) {
            margin-top: 46px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 56px;
        }
    }

    .hero-icons {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: 0.25;
        mix-blend-mode: overlay;

        .hero-icons-left,
        .hero-icons-right {
            position: absolute;
            top: 50%;
            width: 33vw;
            max-width: 300px;
            height: 50vw;
            min-height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translateY(-50%);

            @include media-breakpoint-up(md) {
                width: 25vw;
            }
        }

        .hero-icons-left {
            left: 0;
            background-image: url('../img/interface/hero-icons/hero-left.svg');
            background-position: left bottom;

            @include media-breakpoint-up(md) {
                background-position: left center;
            }
        }

        .hero-icons-right {
            right: 0;
            background-image: url('../img/interface/hero-icons/hero-right.svg');
            background-position: right bottom;

            @include media-breakpoint-up(md) {
                background-position: right center;
            }
        }
    }

    &.hero-white {
        color: revert;
        background: $white;

        .hero-container {
    
            .richtext {
                
                p {
                    color: revert;
                }
            }
        }

        .hero-icons {
            opacity: 0.25;
            mix-blend-mode: normal;
    
            .hero-icons-left {
                background-image: url('../img/interface/hero-icons/hero-left-white.svg');
            }
    
            .hero-icons-right {
                background-image: url('../img/interface/hero-icons/hero-right-white.svg');
            }
        }
    }
}