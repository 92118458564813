.breadcrumbs {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 1.5em 0;
    list-style: none;
    font-size: 12px;
    color: $mediumgrey;
    border-bottom: 1px solid rgba(0,0,0,0.06);

    @include media-breakpoint-up(md) {
        display: flex;
    }

    li {

        &::after {
            content: "navigate_next";
            display: inline-block;
            font-family: "Material Icons";
            margin: 0 0.5em;
            transform: translateY(2px);
        }

        &:last-child {
            font-weight: 600;
            color: $black;

            &::after {
                display: none;
            }
        }

        a {
            display: inline-block;
            text-decoration: underline;
            color: $mediumgrey;
        }
    }
}