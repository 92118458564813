.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    font-weight: 600;
    color: $white;
    border-radius: 8px;
    border: none;
    background: $green;
    transition: color 0.2s, border 0.2s, background-color 0.2s;

    @include media-breakpoint-up(md) {
        height: 44px;
        padding: 0 22px;
    }

    @include media-breakpoint-up(xl) {
        height: 48px;
        padding: 0 24px;
    }

    * + ion-icon,
    * + .material-icons,
    ion-icon + *,
    .material-icons + * {
        margin-left: 0.25em;
    }

    .material-icons {
        font-size: 18px;
    }

    &:hover {
        color: $white;
        background: #33F283;
    }

    &.btn-full {
        width: 100%;
    }

    &.btn-sm {
        height: 32px;
        padding: 0 12px;

        @include media-breakpoint-up(md) {
            height: 36px;
            padding: 0 16px;
        }
    
        @include media-breakpoint-up(xl) {
            height: 40px;
            padding: 0 20px;
        }
    }

    &.btn-opaque {
        background: rgba(255,255,255,0.1);

        &:hover {
            background: rgba(255,255,255,0.2);
        }
    }

    &.btn-stroke {
        color: $green;
        border: 1px solid $green;
        background: transparent;

        &:hover {
            color: $white;
            background: $green;
        }
    }
}

.heading-lg + .btn {
    margin-top: 24px;
}

.richtext + .btn {
    margin-top: 32px;
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

    .btn {
        margin: 4px;
    }
}

.text-center {

    .btn-group {
        justify-content: center;
    }
}

.richtext + .btn-group {
    margin-top: 44px;
}