// Padding
.px-container { padding-left: 5vw; padding-right: 5vw; }
.py-lg { padding-top: 64px; padding-bottom: 64px; @include media-breakpoint-up(md) { padding-top: 80px; padding-bottom: 80px; } @include media-breakpoint-up(xl) { padding-top: 96px; padding-bottom: 96px; } }

// Margin
.m-0 { margin: 0; }

// Headings
.heading-xs { margin: 0 0 0.5em 0; font-weight: 600; font-size: 16px; @include media-breakpoint-up(md) { font-size: 17px; } @include media-breakpoint-up(xl) { font-size: 18px; } &:last-child { margin-bottom: 0; } }
.heading-sm { margin: 0 0 0.5em 0; font-weight: 600; font-size: 18px; line-height: 1.4; @include media-breakpoint-up(md) { font-size: 20px; } @include media-breakpoint-up(xl) { font-size: 21px; } &:last-child { margin-bottom: 0; } }
.heading-md { margin: 0 0 0.5em 0; font-weight: 600; font-size: 20px; line-height: 1.4; @include media-breakpoint-up(md) { font-size: 22px; } @include media-breakpoint-up(xl) { font-size: 24px; } &:last-child { margin-bottom: 0; } }
.heading-lg { margin: 0 0 0.5em 0; font-weight: 600; font-size: 24px; line-height: 1.3; @include media-breakpoint-up(md) { font-size: 28px; } @include media-breakpoint-up(xl) { font-size: 32px; } &:last-child { margin-bottom: 0; } }
.heading-xl { margin: 0 0 0.5em 0; font-weight: 600; font-size: 48px; &:last-child { margin-bottom: 0; } }
.heading-2xl { margin: 0 0 0.5em 0; font-weight: 700; font-size: 32px; @include media-breakpoint-up(md) { font-size: 44px; } @include media-breakpoint-up(xl) { font-size: 56px; } &:last-child { margin-bottom: 0; } }

// Background colours
.bg-darkestgrey { background-color: $darkestgrey; color: $white; }
.bg-lightestgrey { background-color: $lightestgrey; }
.bg-white { background-color: $white; }