// Testimonials section
.home-testimonials-section {

    > * {
        position: relative;
        z-index: 2;
    }

    .home-testimonials-container {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 560px;
        margin: 0 auto;

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &::before {
            right: 0;
            top: 0;
            width: 150px;
            height: 150px;
            background-image: url('../img/interface/home-testimonials-stars.svg');
            transform: translate(50%,-60%);
        }

        &::after {
            left: 0;
            bottom: 0;
            width: 100px;
            height: 100px;
            background-image: url('../img/interface/home-testimonials-chat.svg');
            transform: translate(-70%,0%);
        }

        .home-testimonials-carousel {
            position: relative;
            z-index: 1;
            width: 100%;
            overflow: hidden;
            border-radius: 10px;
            background-color: $white;
            box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);

            .home-testimonials-carousel-slide {
                padding: 40px;
                text-align: center;

                .rating {
                    display: flex;
                    justify-content: center;
                    min-height: 16px;

                    ion-icon {
                        margin: 0 2px;
                        color: $lightgrey;

                        &.filled {
                            color: $pink;
                        }
                    }

                    + .heading-sm {
                        margin-top: 1.5em;
                    }
                }

                .heading-sm {
                    color: $black;
                }

                .richtext {

                    p {
                        color: $darkgrey;
                    }
                }

                .ref {
                    margin-top: 1.5em;
                    font-size: 14px;
                    color: $black;

                    .name {
                        font-weight: 500;
                    }

                    a {
                        font-weight: 600;
                        text-decoration: underline;
                        color: $black;
                        transition: color 0.2s;

                        &:hover {
                            color: $pink;
                        }
                    }
                }
            }
        }

        .home-testimonials-pagination {
            position: relative;
            bottom: auto;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 16px;

            @include media-breakpoint-up(md) {
                margin-top: 24px;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 32px;
            }

            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                margin: 0 6px;
                border-radius: 50%;
                background: $white;
                opacity: 0.25;
                transition: opacity 0.4s;

                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
        }

        .home-testimonials-prev,
        .home-testimonials-next {
            position: absolute;
            z-index: 20;
            top: calc(50% - 19px);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            min-width: 24px;
            padding: 0;
            color: $white;
            border-radius: 50%;
            border: none;
            background: $darkgrey;

            @include media-breakpoint-up(md) {
                width: 28px;
                height: 28px;
            }
        }

        .home-testimonials-prev {
            right: 100%;
            transform: translate(50%,-50%);

            @include media-breakpoint-up(md) {
                margin-right: 32px;
                transform: translate(0,-50%);
            }
        }

        .home-testimonials-next {
            left: 100%;
            transform: translate(-50%,-50%);

            @include media-breakpoint-up(md) {
                margin-left: 32px;
                transform: translate(0,-50%);
            }
        }
    }
}

.heading-md + .home-testimonials-container {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 40px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 48px;
    }
}

// Blog section
.home-blog-section {
    padding-top: 48px;
    border-top: 1px solid rgba(255,255,255,0.06);

    @include media-breakpoint-up(md) {
        padding-top: 56px;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 64px;
    }

    .home-blog-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 36px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 40px;
        }

        .btn {
            color: $mediumgrey;
            border: 1px solid $darkgrey;
            background: transparent;

            &:hover {
                color: $white;
                border: 1px solid $mediumgrey;
                background: transparent;
            }
        }
    }

    .home-blog-carousel {
        position: relative;

        .swiper-wrapper {

            .swiper-slide {
                height: auto;
            }
        }

        .home-blog-carousel-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            padding: 32px;
            color: $white;
            border-radius: 10px;
            border: 1px solid rgba(255,255,255,0.05);
            background: rgba(255,255,255,0.02);
            transform: translateY(0);
            transition: border 0.2s, background-color 0.2s, transform 0.2s;

            .top {
                flex: 1;

                .date {
                    margin: 0 0 1em 0;
                    font-size: 14px;
                    color: $mediumgrey;
                }
            }

            .author {
                display: flex;
                align-items: center;
                margin-top: 48px;

                .author-image {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background-position: center;
                    background-size: cover;

                    + .author-name {
                        margin-left: 8px;
                    }
                }

                .author-name {
                    font-weight: 500;
                    font-size: 14px;

                    &::before {
                        content: 'by\00a0';
                        font-weight: 300;
                        color: $mediumgrey;
                    }
                }
            }

            @media (hover: hover) and (pointer: fine) {

                &:hover {
                    border: 1px solid rgba(255,255,255,0.1);
                    background: rgba(255,255,255,0.04);
                    transform: translateY(-4px);
                }
            }
        }

        .home-blog-prev,
        .home-blog-next {
            position: absolute;
            z-index: 20;
            top: -1px;
            bottom: -1px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 10vw;
            min-width: 24px;
            padding: 0;
            color: $white;
            border: none;
            background-color: transparent;
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.3s;

            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }

            .home-blog-nav-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: $darkgrey;
    
                @include media-breakpoint-up(md) {
                    width: 28px;
                    height: 28px;
                }
            }
        }

        .home-blog-prev {
            left: -5vw;
            background: linear-gradient(90deg, rgba(49,46,63,1) 0%, rgba(49,46,63,0) 100%);
        }

        .home-blog-next {
            right: -5vw;
            background: linear-gradient(270deg, rgba(49,46,63,1) 0%, rgba(49,46,63,0) 100%);
        }
    }
}

.home-testimonials-section + .home-blog-section {
    margin-top: 48px;

    @include media-breakpoint-up(md) {
        margin-top: 72px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 96px;
    }
}