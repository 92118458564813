.docs-listings {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
        margin: 0 -16px;
    }

    li {
        width: 100%;
        margin-top: 32px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            margin-top: 48px;
            padding: 0 16px;

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 33.33%;
            margin-top: 64px;

            &:nth-child(3) {
                margin-top: 0;
            }
        }

        .docs-card {
            display: flex;
            flex-direction: column;
            height: 100%;
            overflow: hidden;
            border-radius: 10px;
            background: $white;
        
            .docs-card-image {
                width: 100%;
                padding-bottom: 67%;
                background-color: $lightgrey;
                background-position: center;
                background-size: cover;
            }

            .docs-card-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 24px;
                border-radius: 0 0 10px 10px;
                border: 1px solid rgba(0,0,0,0.06);
                border-top: none;

                @include media-breakpoint-up(lg) {
                    padding: 32px;
                }

                .main {
                    flex: 1;

                    .heading-sm {
                        margin-bottom: 1em;
                    }

                    .docs-card-list {
                        margin-bottom: 16px;

                        .docs-card-list-item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            margin-bottom: 4px;
                            padding: 8px 16px;
                            font-weight: 500;
                            color: $darkgrey;
                            border-radius: 8px;
                            border: 1px solid rgba(0,0,0,0.06);
                            background: $lightestgrey;
                            transition: color 0.2s, background-color 0.2s;

                            @include media-breakpoint-up(md) {
                                padding: 9px 18px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .number {
                                flex-shrink: 0;
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                min-width: 20px;
                                height: 20px;
                                margin-left: 18px;
                                padding: 0 6px;
                                font-size: 12px;
                                color: white;
                                border-radius: 10px;
                                background: $lightgrey;
                                transition: background-color 0.2s;
                            }

                            &:hover {
                                color: $black;
                                background: $white;

                                .number {
                                    background: $pink;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.docs-body {
    margin: 0 0 32px 0;
    padding: 0 0 32px 0;
    border-bottom: 1px solid rgba(0,0,0,0.06);

    @include media-breakpoint-up(lg) {
        margin: 0;
        padding: 0 64px 0 0;
        border: none;
    }
}

.docs-category-section {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.06);
    background: $white;

    @include media-breakpoint-up(lg) {
        padding: 32px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin: 0 0 24px 0;

        .number {
            flex-shrink: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 24px;
            height: 24px;
            padding: 0 8px;
            font-weight: 500;
            font-size: 12px;
            color: $white;
            border-radius: 12px;
            background: $pink;
        }
    }

    .docs-category-list {

        .docs-category-list-item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            vertical-align: baseline;
            width: 100%;
            margin-bottom: 4px;
            padding: 8px 16px;
            font-weight: 500;
            color: $darkgrey;
            border-radius: 8px;
            border: 1px solid rgba(0,0,0,0.06);
            background: $lightestgrey;
            transition: color 0.2s, border 0.2s, background-color 0.2s;

            @include media-breakpoint-up(md) {
                padding: 12px 18px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .content {
                flex: 1;
                display: flex;
                
                .content-icon {
                    display: inline-flex;
                    align-items: center;
                    height: 1.5em;
                    margin-right: 0.75em;
                    color: $lightgrey;
                    transition: color 0.2s;
                }

                .text {
                    display: inline-block;
                }
            }

            .arrow {
                margin: 4px 0 0 18px;
                font-size: 14px;
                transition: transform 0.2s;
            }

            &:hover {
                color: $black;
                border: 1px solid rgba(0,0,0,0.12);
                background: $white;

                .content {
                    flex: 1;
                    display: flex;
                    
                    .content-icon {
                        color: $pink;
                    }
                }

                .arrow {
                    transform: translateX(4px);
                }
            }
        }
    }

    + .docs-category-section {
        margin-top: 16px;
    }
}

.docs-support {
    padding: 32px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.06);
    background: rgba(0,0,0,0.02);
}

.docs-status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    font-weight: 500;
    font-size: 12px;
    color: $mediumgrey;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.06);

    .docs-status-colour {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-radius: 50%;

        &.green {
            background: $green;
        }
    }
}

* + .docs-support,
* + .docs-status {
    margin-top: 16px;
}

.docs-feedback {
    padding: 32px 0;
    text-align: center;
    border-top: 1px solid rgba(0,0,0,0.06);
    border-bottom: 1px solid rgba(0,0,0,0.06);

    .docs-feedback-heading {
        margin: 0 0 0.75em 0;
        font-weight: 500;
        color: $black;
    }

    .docs-feedback-links {
        display: flex;
        justify-content: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            margin: 0 4px;
            color: $white;
            border-radius: 50%;

            ion-icon {
                font-size: 20px;
            }

            &.yes {
                background: $green;
            }

            &.no {
                background: #E36060;
            }
        }
    }
}

* + .docs-feedback,
.docs-feedback + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}

.docs-entry-nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 32px 0 0 0;
    list-style: none;
    border-top: 1px solid rgba(0,0,0,0.06);

    @include media-breakpoint-up(sm) {
        margin: 0 -4px;
    }

    @include media-breakpoint-up(lg) {
        margin: 0 -8px;
    }

    li {
        width: 100%;
        margin-top: 8px;
        padding: 0;

        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-up(sm) {
            width: 50%;
            margin-top: 0;
            padding: 0 4px;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 8px;
        }

        a {
            display: block;
            padding: 16px 24px;
            border-radius: 10px;
            border: 1px solid rgba(0,0,0,0.06);
            background: rgba(0,0,0,0.02);
            transform: translateY(0);
            transition: border 0.2s, background-color 0.2s, transform 0.2s;

            @include media-breakpoint-up(md) {
                padding: 24px 32px;
            }

            .title {
                display: inline-flex;
                align-items: center;
                margin: 0 0 0.5em 0;
                font-weight: 500;
                font-size: 14px;
                color: $mediumgrey;

                * + ion-icon,
                ion-icon + * {
                    margin-left: 0.5em;
                }
            }

            .article-title {
                color: $black;
            }

            &.docs-entry-nav-prev {
                text-align: center;

                @include media-breakpoint-up(sm) {
                    text-align: left;
                }
            }

            &.docs-entry-nav-next {
                text-align: center;

                @include media-breakpoint-up(sm) {
                    text-align: right;
                }
            }

            @media (hover: hover) and (pointer: fine) {

                &:hover {
                    border: 1px solid rgba(0,0,0,0.1);
                    background: rgba(0,0,0,0.04);
                    transform: translateY(-4px);
                }
            }
        }
    }
}

* + .docs-entry-nav {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}