.trusted-brands {
    position: relative;

    .trusted-brands-header {
        position: absolute;
        z-index: 20;
        display: flex;
        justify-content: center;
        width: 100%;
        transform: translateY(-50%);

        .trusted-brands-header-text {
            padding: 4px 12px;
            font-weight: 600;
            font-size: 10px;
            color: $mediumgrey;
            border-radius: 40px;
            border: 1px solid rgba(0,0,0,0.06);
            background: $white;

            @include media-breakpoint-up(md) {
                padding: 6px 16px;
                font-size: 11px;
            }

            @include media-breakpoint-up(xl) {
                padding: 8px 20px;
                font-size: 12px;
            }
        }

        &.trusted-brands-header-opaque {

            .trusted-brands-header-text {
                color: $white;
                border: none;
                background: #E1B4E6;

                // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                //     color: $white;
                //     backdrop-filter: blur(30px);
                //     background-color: rgba(255, 255, 255, 0.25);
                // }
            }
        }
    }

    .trusted-brands-carousel {
        position: relative;
        overflow: hidden;
        padding: 32px 0 24px 0;

        @include media-breakpoint-up(md) {
            padding: 40px 0 32px 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 48px 0 40px 0;
        }
        
        .swiper-wrapper {

            .swiper-slide {
                width: auto;
                height: auto;
            }
        }

        .trusted-brands-carousel-item {
            display: flex;
            align-items: center;
            height: 100%;
        }

        .trusted-brands-carousel-prev,
        .trusted-brands-carousel-next {
            position: absolute;
            z-index: 20;
            top: -1px;
            bottom: -1px;
            display: flex;
            align-items: center;
            width: 10vw;
            min-width: 24px;
            padding: 0;
            color: $white;
            border: none;
            background-color: transparent;
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.3s;

            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }

            .trusted-brands-carousel-nav-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: $darkgrey;
            }
        }

        .trusted-brands-carousel-prev {
            left: 0;
            justify-content: flex-start;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        }

        .trusted-brands-carousel-next {
            right: 0;
            justify-content: flex-end;
            background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        }
    }

    &.trusted-brands-bt {
        border-top: 1px solid rgba(0,0,0,0.06);
    }

    &.trusted-brands-bb {
        border-bottom: 1px solid rgba(0,0,0,0.06);
    }
}

.bg-lightestgrey {

    .trusted-brands {
    
        .trusted-brands-header {
    
            .trusted-brands-header-text {
                background: $lightestgrey;
            }
        }
    }

    .trusted-brands-carousel {

        .trusted-brands-carousel-prev {
            background: linear-gradient(90deg, rgba(249,250,251,1) 0%, rgba(249,250,251,0) 100%);
        }

        .trusted-brands-carousel-next {
            background: linear-gradient(90deg, rgba(249,250,251,0) 0%, rgba(249,250,251,1) 100%);
        }
    }
}

.divider {
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(0,0,0,0.06);
}

* + .divider,
.divider + * {
    margin-top: 64px;

    @include media-breakpoint-up(md) {
        margin-top: 80px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 96px;
    }
}

* + .divider-sm,
.divider-sm + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}