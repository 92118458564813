.feature-requests-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-bottom: 1px solid rgba(0,0,0,0.06);

    @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
        padding-top: 0;
    }

    .feature-requests-nav {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }

        li {
            margin: 0 1em;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                position: relative;
                display: inline-flex;
                align-items: center;
                height: 56px;
                padding-top: 0.5em;
                font-weight: 600;
                color: $darkgrey;

                @include media-breakpoint-up(md) {
                    height: 64px;
                }

                @include media-breakpoint-up(xl) {
                    height: 72px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    width: 100%;
                    height: 2px;
                    background: $black;
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
            }

            &.active {

                a {
                    color: $black;

                    &::after {
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
            }
        }
    }

    .feature-requests-dropdown {
        position: relative;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
        }

        .dropdown-toggle {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-width: 200px;
            height: 40px;
            padding: 0 16px;
            font-weight: 500;
            color: $black;
            border-radius: 10px;
            border: 1px solid rgba(0,0,0,0.12);
            background: $white;
            box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.02);

            @include media-breakpoint-up(sm) {
                width: auto;
            }

            &::after {
                display: none;
            }

            &[aria-expanded="true"] {
                border-radius: 10px 10px 0 0;
                border-bottom: 1px solid rgba(0,0,0,0.06);

                @include media-breakpoint-up(sm) {
                    border-radius: 10px;
                    border-bottom: 1px solid rgba(0,0,0,0.12);
                }

                ion-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .dropdown-menu {
            width: 100%;
            min-width: 200px;
            margin: 0;
            border-radius: 0 0 10px 10px;
            border: 1px solid rgba(0,0,0,0.12);
            border-top: none;

            @include media-breakpoint-up(sm) {
                width: auto;
                margin-top: 4px;
                border-radius: 10px;
                border-top: 1px solid rgba(0,0,0,0.12);
            }

            a {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px 16px;
                font-weight: 300;
                cursor: pointer;
                color: $darkgrey;
                transition: color 0.2s, background-color 0.2s;

                .number {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 24px;
                    height: 24px;
                    margin-left: 16px;
                    padding: 0 8px;
                    font-size: 10px;
                    font-weight: 500;
                    color: $mediumgrey;
                    border-radius: 12px;
                    background: $lightestgrey;
                    transition: color 0.2s, background-color 0.2s;
                }

                &:hover {
                    color: $black;
                    background-color: $lightestgrey;

                    .number {
                        color: $pink;
                        background: $white;
                    }
                }
            }
        }
    }
}

.feature-requests-listings {

    .feature-requests-listings-item {
        margin-top: 16px;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.06);
        background: $white;

        &:first-child {
            margin-top: 0;
        }

        .main {
            display: flex;
            flex-direction: column;
            padding: 32px;

            @include media-breakpoint-up(sm) {
                flex-direction: row-reverse;
                align-items: flex-start;
            }

            .vote-container {
                flex-shrink: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                margin: 32px 0 0 0;
                padding: 16px;
                border-radius: 8px;
                border: 1px solid rgba(0,0,0,0.06);
                background: $lightestgrey;

                @include media-breakpoint-up(sm) {
                    flex-direction: column;
                    width: 120px;
                    margin: 0 32px 0 0;
                }

                .vote-number {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 50%;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 1.4;
                    text-align: center;
                    color: $black;
                    border-right: 1px solid rgba(0,0,0,0.06);

                    @include media-breakpoint-up(sm) {
                        width: auto;
                        margin-bottom: 16px;
                        border: none;
                    }

                    &::after {
                        content: 'Votes';
                        font-weight: 300;
                        font-size: 12px;
                        color: $darkgrey;
                    }
                }

                .vote-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;

                    @include media-breakpoint-up(sm) {
                        width: auto;
                    }
                }
            }

            .content {

                @include media-breakpoint-up(sm) {
                    flex: 1;
                }

                .heading-sm {
                    color: $black;
                }

                .snippet {
                    color: $darkgrey;

                    .more {
                        font-weight: 500;
                        text-decoration: underline;
                        color: $pink;
                    }
                }

                &:hover {

                    .heading-sm {
                        text-decoration: underline;
                    }
                }
            }
        }

        .footer {
            padding: 16px 32px;
            font-weight: 500;
            font-size: 12px;
            border-top: 1px solid rgba(0,0,0,0.06);

            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                list-style: none;

                li {

                    &::after {
                        content: '•';
                        margin: 0 0.5em;
                        color: $lightgrey;
                    }

                    &:last-child {

                        &::after {
                            display: none;
                        }
                    }

                    &.status {

                        span {
                            padding: 4px 10px;
                            color: $white;
                            border-radius: 20px;
                            box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.06);

                            &.under-review {
                                background: $lightgrey;
                            }

                            &.planned {
                                background: #FFAD3C;
                            }

                            &.started {
                                background: #3C9EFF;
                            }
                        }
                    }

                    &.comments {
                        color: $mediumgrey;
                    }
                }
            }
        }
    }
}

.feature-requests-sidebar {
    padding: 32px 0 0 0;

    @include media-breakpoint-up(lg) {
        padding: 0 0 0 32px;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 0 0 64px;
    }

    .feature-requests-login {
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.06);
        background: rgba(0,0,0,0.02);

        .main {
            padding: 32px;
        }

        .feature-requests-login-form {

            label {
                font-size: 12px;

                + input {
                    margin-top: 6px;
                }
            }

            input {
                height: 40px;
                margin: 0;

                + label {
                    margin-top: 12px;
                }
            }
        }

        .heading-sm + .feature-requests-login-form {
            margin-top: 21px;
        }

        .footer {
            padding: 16px 32px;
            font-size: 14px;
            text-align: center;
            color: $darkgrey;
            border-top: 1px solid rgba(0,0,0,0.06);

            a {
                font-weight: 500;
                text-decoration: underline;
                color: $black;
            }
        }
    }

    .powered-by {
        padding: 16px;
        font-size: 12px;
        text-align: center;
        color: $lightgrey;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.06);

        a {
            font-weight: 500;
            text-decoration: underline;
            color: $mediumgrey;
        }
    }

    * + .feature-requests-login,
    * + .powered-by {
        margin-top: 16px;
    }
}