.thankyou-steps {

    .thankyou-steps-item {
        display: flex;
        flex-wrap: wrap;
        padding: 32px;
        text-align: center;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.06);
        background: $white;

        @include media-breakpoint-up(sm) {
            text-align: left;
        }

        .icon {
            margin: 0 auto 24px auto;
            background: $lightestgrey;

            @include media-breakpoint-up(sm) {
                margin: 0 32px 0 0;
            }
        }

        .content {
            width: 100%;

            @include media-breakpoint-up(sm) {
                flex: 1;
                width: auto;
            }

            .heading-sm {
                color: $black;
            }
        }

        + .thankyou-steps-item {
            margin-top: 16px;
        }

        &:hover {

            .content {

                .heading-sm {
                    text-decoration: underline;
                }
            }
        }
    }
}

.heading-md + .thankyou-steps {
    margin-top: 16px;

    @include media-breakpoint-up(md) {
        margin-top: 24px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 32px;
    }
}

.thankyou-steps + .heading-md {
    margin-top: 48px !important;

    @include media-breakpoint-up(md) {
        margin-top: 72px !important;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 96px !important;
    }
}