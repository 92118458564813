.site-header {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    width: 100%;

    &::after {
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $white;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.1);
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.4s;
    }

    .site-header-container {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;

        @include media-breakpoint-up(md) {
            height: 64px;
        }

        @include media-breakpoint-up(lg) {
            height: 72px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: rgba(255,255,255,0.1);
            transform-origin: center;
            transform: scaleX(1);
            transition: transform 0.4s;
        }
    }

    .site-header-logo {
        position: relative;
        display: block;
        height: 32px;

        @include media-breakpoint-up(lg) {
            height: 36px;
        }

        svg {
            display: block;
            width: auto;
            height: 100%;
        }

        .site-header-logo-primary,
        .site-header-logo-inverted {
            transition: opacity 0.4s;
        }

        .site-header-logo-primary {
            opacity: 1;
        }

        .site-header-logo-inverted {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .site-menu-trigger {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        padding: 0;
        color: $white;
        border: none;
        background: none;
        transition: color 0.4s;

        @include media-breakpoint-up(md) {
            display: none;
        }

        ion-icon {
            font-size: 32px;
        }
    }

    .site-header-nav {
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
        font-weight: 500;
        font-size: 14px;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }

        li {
            margin: 0 1em;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                display: block;
                color: $white;
                transition: color 0.4s;
            }
        }
    }

    &.site-header-inverted {

        .site-header-container {

            &::after {
                background: rgba(0,0,0,0.06);
            }

            .site-header-logo {

                .site-header-logo-primary {
                    opacity: 0;
                }
        
                .site-header-logo-inverted {
                    opacity: 1;
                }
            }

            .site-menu-trigger {
                color: $black;
            }

            .site-header-nav {
        
                li {
        
                    a {
                        color: $black;
                    }
                }
            }
        }
    }

    &.site-scrolled {

        &::after {
            transform: scaleY(1);
        }

        .site-header-container {
    
            &::after {
                transform: scaleX(0);
            }

            .site-header-logo {

                .site-header-logo-primary {
                    opacity: 0;
                }
        
                .site-header-logo-inverted {
                    opacity: 1;
                }
            }

            .site-menu-trigger {
                color: $black;
            }

            .site-header-nav {
        
                li {
        
                    a {
                        color: $black;
                    }
                }
            }
        }
    }
}