.blog-header {
    position: sticky;
    z-index: 10;
    top: 56px;
    background: $white;
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.06);

    @include media-breakpoint-up(md) {
        top: 64px;
    }

    @include media-breakpoint-up(lg) {
        top: 72px;
    }

    .blog-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;

        @include media-breakpoint-up(md) {
            height: 64px;
        }
    
        @include media-breakpoint-up(lg) {
            height: 72px;
        }

        .blog-header-dropdown {
            position: relative;
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: auto;
            }

            .dropdown-toggle {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                min-width: 200px;
                height: 40px;
                padding: 0 16px;
                font-weight: 500;
                color: $black;
                border-radius: 10px;
                border: 1px solid rgba(0,0,0,0.12);
                background: $white;
                box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.02);

                @include media-breakpoint-up(sm) {
                    width: auto;
                }

                &::after {
                    display: none;
                }

                &[aria-expanded="true"] {
                    border-radius: 10px 10px 0 0;
                    border-bottom: 1px solid rgba(0,0,0,0.06);

                    @include media-breakpoint-up(sm) {
                        border-radius: 10px;
                        border-bottom: 1px solid rgba(0,0,0,0.12);
                    }

                    ion-icon {
                        transform: rotate(180deg);
                    }
                }
            }

            .dropdown-menu {
                width: 100%;
                min-width: 200px;
                margin: 0;
                border-radius: 0 0 10px 10px;
                border: 1px solid rgba(0,0,0,0.12);
                border-top: none;

                @include media-breakpoint-up(sm) {
                    width: auto;
                    margin-top: 4px;
                    border-radius: 10px;
                    border-top: 1px solid rgba(0,0,0,0.12);
                }

                a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 16px;
                    font-weight: 300;
                    cursor: pointer;
                    color: $darkgrey;
                    transition: color 0.2s, background-color 0.2s;

                    .number {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 24px;
                        height: 24px;
                        margin-left: 16px;
                        padding: 0 8px;
                        font-size: 10px;
                        font-weight: 500;
                        color: $mediumgrey;
                        border-radius: 12px;
                        background: $lightestgrey;
                        transition: color 0.2s, background-color 0.2s;
                    }

                    &:hover {
                        color: $black;
                        background-color: $lightestgrey;

                        .number {
                            color: $pink;
                            background: $white;
                        }
                    }
                }
            }
        }


        .blog-header-search {
            position: relative;
            margin: 8px 16px;

            @include media-breakpoint-up(sm) {
                margin: 0;
            }

            input {
                position: relative;
                z-index: 1;
                display: inline-block;
                width: 100%;
                min-width: 200px;
                height: 40px;
                padding: 0 16px;
                font-weight: 300;
                color: $darkgrey;
                border-radius: 10px;
                border: 1px solid rgba(0,0,0,0.1);
                background: $lightestgrey;
                box-shadow: none;
                appearance: none;
                outline: none;
                transition: color 0.2s, border 0.2s, background-color 0.2s;

                @include media-breakpoint-up(sm) {
                    width: auto;
                }

                &:focus {
                    color: $black;
                    border: 1px solid $pink;
                    background: $white;
                }
            }

            .blog-header-search-submit {
                position: absolute;
                z-index: 2;
                right: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                padding: 0;
                border: none;
                background: transparent;
                
                ion-icon {
                    font-size: 20px;
                }
            }
        }
    }
}

.blog-listings {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding: 48px 0;
            border-bottom: 1px solid rgba(0,0,0,0.06);

            &:first-child {
                padding-top: 0;
            }
    
            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }
        }

        @include media-breakpoint-up(xl) {
            padding: 64px 0;
        }

        .blog-listings-item {
            display: flex;
            flex-wrap: wrap;
            border-radius: 10px;
            border: 1px solid rgba(0,0,0,0.06);

            @include media-breakpoint-up(md) {
                flex-direction: row-reverse;
                border-radius: 0;
                border: none;
            }

            .content {
                width: 100%;
                padding: 32px;

                @include media-breakpoint-up(md) {
                    width: 75%;
                    padding: 0;
                }

                .heading-md {
                    color: $black;
                }

                .snippet {
                    color: $darkgrey;

                    &::after {
                        content: 'Continue reading';
                        margin-left: 3px;
                        font-weight: 500;
                        text-decoration: underline;
                        color: $pink;
                    }
                }

                &:hover {

                    .heading-md {
                        text-decoration: underline;
                    }
                }
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 12px 32px;
                border-top: 1px solid rgba(0,0,0,0.06);

                @include media-breakpoint-up(md) {
                    display: block;
                    width: 25%;
                    padding: 0 32px 0 0;
                    border: none;
                }

                .author,
                .date {
                    font-size: 14px;

                    @include media-breakpoint-up(xl) {
                        font-size: 16px;
                    }
                }

                .author {
                    display: inline-block;
                    font-weight: 500;
                    color: $black;
                    transition: color 0.2s;

                    &:hover {
                        color: $pink;
                    }
                }

                .date {
                    color: $mediumgrey;
                }

                .category {
                    display: inline-flex;
                    align-items: center;
                    height: 24px;
                    padding: 0 12px;
                    font-weight: 500;
                    font-size: 12px;
                    color: $darkgrey;
                    border-radius: 12px;
                    border: 1px solid rgba(0,0,0,0.06);
                    background: $lightestgrey;
                    transition: color 0.2s, border 0.2s, background-color 0.2s;

                    @include media-breakpoint-up(md) {
                        margin-top: 16px;
                    }

                    &:hover {
                        color: $pink;
                        border: 1px solid rgba(209,3,226,0.06);
                        background: rgba(209,3,226,0.08);
                    }
                }
            }
        }
    }
}

.article-hero {
    position: relative;
    z-index: 2;

    .article-hero-details {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        list-style: none;

        li {

            &::after {
                content: '•';
                margin: 0 0.5em;
                color: rgba(255,255,255,0.25);
            }

            &:last-child {
                
                &::after {
                    display: none;
                }
            }

            &.author {
                font-weight: 500;
                color: $white;

                &::before {
                    content: 'By\00a0';
                    font-weight: 300;
                }

                a {
                    color: $white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.date {
                color: rgba(255,255,255,0.5);
            }
        }
    }

    .article-hero-category {
        position: absolute;
        z-index: 2;
        left: 50%;
        bottom: 0;
        padding: 4px 12px;
        font-size: 10px;
        color: $white;
        border-radius: 40px;
        background: #E1B4E6;
        transform: translate(-50%,50%);

        @include media-breakpoint-up(md) {
            padding: 6px 16px;
            font-size: 11px;
        }

        @include media-breakpoint-up(xl) {
            padding: 8px 20px;
            font-size: 12px;
        }

        a {
            font-weight: 600;
            text-decoration: underline;
            color: $white;
        }

        // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        //     color: $white;
        //     backdrop-filter: blur(30px);
        //     background-color: rgba(255, 255, 255, 0.25);

        //     a {
        //         color: $white;
        //     }
        // }
    }

    + * {
        position: relative;
        z-index: 1;
    }
}

.article-product-trial {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    text-align: center;
    color: $white;
    border-radius: 10px;
    background-color: $black;
    background-position: center;
    background-size: cover;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);

    @include media-breakpoint-up(md) {
        padding: 40px 48px;
    }

    @include media-breakpoint-up(xl) {
        padding: 48px 64px;
    }

    .logo {
        height: 24px;
        margin: 0 0 36px 0;

        svg {
            display: block;
            width: auto;
            height: 100%;
        }
    }

    .heading-lg {
        max-width: 18em;
    }

    .more-link {
        font-weight: 500;
        text-decoration: underline;
        color: $white;
    }

    .btn + .more-link {
        margin-top: 1em;
    }
}

* + .article-product-trial,
.article-product-trial + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}

.article-share {
    padding: 32px 0;
    text-align: center;
    border-top: 1px solid rgba(0,0,0,0.06);
    border-bottom: 1px solid rgba(0,0,0,0.06);

    .article-share-heading {
        margin: 0 0 0.75em 0;
        font-weight: 500;
        color: $black;
    }

    .article-share-links {
        display: flex;
        justify-content: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            margin: 0 4px;
            color: $white;
            border-radius: 50%;

            ion-icon {
                font-size: 20px;
            }

            &.facebook {
                background: #5067A3;
            }

            &.linkedin {
                background: #0077B5;
            }

            &.twitter {
                background: #38B2F0;
            }
        }
    }
}

* + .article-share,
.article-share + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}