.site-footer {
    color: $white;
    background: $black;
    
    .site-footer-support {
        padding: 48px 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 72px 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 96px 0;
        }

        .body {
            font-size: 20px;
            line-height: 1.5;
            color: $lightgrey;

            @include media-breakpoint-up(md) {
                font-size: 22px;
            }
    
            @include media-breakpoint-up(xl) {
                font-size: 24px;
            }

            + .btn {
                margin-top: 30px;

                @include media-breakpoint-up(md) {
                    margin-top: 33px;
                }
        
                @include media-breakpoint-up(xl) {
                    margin-top: 36px;
                }
            }
        }
    }

    .site-footer-main {
        padding: 48px 0;

        @include media-breakpoint-up(md) {
            padding: 72px 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 96px 0;
        }

        .site-footer-logo {
            display: inline-block;
            margin-bottom: 32px;

            @include media-breakpoint-up(md) {
                margin-bottom: 48px;
            }

            svg {
                display: block;
            }
        }

        .site-footer-heading,
        .site-footer-nav {
            font-size: 14px;
        }

        .site-footer-heading {
            margin: 0 0 0.5em 0;
            font-weight: 500;

            @include media-breakpoint-up(md) {
                margin: 0 0 0.75em 0;
            }

            @include media-breakpoint-up(xl) {
                margin: 0 0 1em 0;
            }
        }

        .site-footer-nav {
            margin: 0 0 2em 0;
            padding: 0;
            list-style: none;
            line-height: 2;

            @include media-breakpoint-up(sm) {
                margin: 0;
            }

            li {

                a {
                    display: inline-block;
                    color: $mediumgrey;
                    transition: color 0.4s;

                    &:hover {
                        color: $white;
                    }
                }
            }
        }
    }

    .site-footer-support + .site-footer-main {
        border-top: 1px solid rgba(255,255,255,0.06);
    }

    .site-footer-bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 32px;
        color: $darkgrey;

        @include media-breakpoint-up(md) {
            padding-bottom: 48px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 64px;
        }

        .site-footer-bottom-left {
            font-size: 12px;

            .message {
                max-width: 17.5em;
                margin: 0 0 1em 0;
            }
        }

        .site-footer-social {
            display: flex;

            a {
                display: inline-block;
                margin: 0 0.75em;
                color: $darkgrey;
                transition: color 0.4s;

                &:hover {
                    color: $white;
                }

                &:first-child {
                    margin-left: 0;
                }
    
                &:last-child {
                    margin-right: 0;
                }

                ion-icon {
                    font-size: 20px;
                }
            }
        }
    }
}