.form {
    text-align: left;

    label,
    legend {
        display: block;
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        color: $black;

        &.required {

            &::after {
                content: '*';
                color: $pink;
            }
        }

        + .label-explainer {
            margin-top: 0.25em;
        }

        + input,
        + textarea {
            margin-top: 8px;

            @include media-breakpoint-up(md) {
                margin-top: 10px;
            }
    
            @include media-breakpoint-up(xl) {
                margin-top: 12px;
            }
        }
    }

    input,
    textarea {
        display: block;
        width: 100%;
        margin: 0 0 12px 0;
        font-weight: 300;
        color: $darkgrey;
        border-radius: 8px;
        border: 1px solid rgba(0,0,0,0.1);
        background: $white;
        outline: none;
        appearance: none;
        transition: color 0.2s, border 0.2s;

        @include media-breakpoint-up(md) {
            margin: 0 0 14px 0;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 0 16px 0;
        }

        &:hover {
            border: 1px solid rgba(0,0,0,0.2);
        }

        &:focus {
            color: $black;
            border: 1px solid $pink;
            background: $white;
        }
    }

    input {
        height: 48px;
        padding: 0 16px;
    }

    textarea {
        min-height: 144px;
        padding: 12px 16px;
    }

    .label-explainer {
        font-size: 12px;
        color: $darkgrey;

        + input,
        + textarea {
            margin-top: 8px;

            @include media-breakpoint-up(md) {
                margin-top: 10px;
            }
    
            @include media-breakpoint-up(xl) {
                margin-top: 12px;
            }
        }
    }

    .rating {

        input[type="radio"]:not(:nth-of-type(0)) {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
            
            + label {
                display: none;
            }
        }
    
        label[for]:hover {
            cursor: pointer;
        }

        .stars {
            display: inline-flex;
            min-height: 32px;
            margin: 0 0 12px 0;

            label {
                display: inline-block;
                padding: 0 2px;
                line-height: 1;
                color: $lightgrey;
                cursor: pointer;
                transition: color 0.2s;
    
                ion-icon {
                    font-size: 32px;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            &:hover {

                label {
                    color: $mediumgrey;

                    &:hover {
                        color: $mediumgrey;

                        ~ label {
                            color: $lightgrey;
                        }
                    }
                }
            }
        }
    
        [type="radio"]:nth-of-type(1):checked ~ .stars label:nth-of-type(-n+1),
        [type="radio"]:nth-of-type(2):checked ~ .stars label:nth-of-type(-n+2),
        [type="radio"]:nth-of-type(3):checked ~ .stars label:nth-of-type(-n+3),
        [type="radio"]:nth-of-type(4):checked ~ .stars label:nth-of-type(-n+4),
        [type="radio"]:nth-of-type(5):checked ~ .stars label:nth-of-type(-n+5) {
            color: $pink;
        }
        
        [type="radio"]:nth-of-type(1):focus ~ .stars label:nth-of-type(1),
        [type="radio"]:nth-of-type(2):focus ~ .stars label:nth-of-type(2),
        [type="radio"]:nth-of-type(3):focus ~ .stars label:nth-of-type(3),
        [type="radio"]:nth-of-type(4):focus ~ .stars label:nth-of-type(4),
        [type="radio"]:nth-of-type(5):focus ~ .stars label:nth-of-type(5) {
            color: $pink;
        }
    }

    // .rating-input {
    //     display: inline-flex;
    //     direction: rtl;
    //     min-height: 32px;
    //     margin: 0 0 12px 0;

    //     input {
    //         position: absolute;
    //         width: 0;
    //         height: 0;
    //         opacity: 0;
    //         pointer-events: none;

    //         + label {
    //             display: inline-block;
    //             padding: 0 2px;
    //             line-height: 1;
    //             color: $lightgrey;
    //             cursor: pointer;
    
    //             ion-icon {
    //                 font-size: 32px;
    //             }

    //             &:last-child {
    //                 padding-left: 0;
    //             }
    //         }

    //         &:hover,
    //         &:checked {

    //             + label {
    //                 color: $pink;
    //             }

    //             ~  label {
    //                 color: $pink;
    //             }
    //         }
    //     }
    // }

    * + .btn {
        margin-top: 16px;
    }
}

* + .form,
.form + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}