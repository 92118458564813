.product-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    transform: translateY(0);
    transition: box-shadow 0.2s, transform 0.2s;

    .product-card-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 32px;

        .product-card-main {

            .heading-sm {
                color: $black;
            }

            .body {
                font-size: 14px;
                line-height: 1.6;
                color: $darkgrey;
            }
        }

        .product-card-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 32px;

            .product-card-price {
                font-weight: 500;
                color: $black;
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {

        &:hover {
            box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
            transform: translateY(-4px);
        }
    }
}

.product-card-image {
    position: relative;
    width: 100%;
    padding-bottom: 67%;
    background-color: $lightgrey;

    &::before,
    .ag-logo {
        content: '';
        z-index: 1;
        position: absolute;
        left: 0;
        width: 100%;
        background-position: center;
    }

    &::before {
        top: 0;
        height: 100%;
        background-image: url('../img/plugins/card-image-bg.svg');
        background-size: cover;
        opacity: 0.1;
    
        @supports (mix-blend-mode: screen) {
            mix-blend-mode: overlay;
            opacity: 0.25;
        }
    }

    .ag-logo {
        bottom: 20px;
        height: 20px;

        img {
            display: block;
            width: auto;
            height: 100%;
            margin: 0 auto;
        }
    }

    .brand-logo {
        position: absolute;
        z-index: 3;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 32px;

        img {
            display: block;
            max-width: 100%;
        }
    }
}

.product-listings {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
        margin: 0 -16px;
    }

    li {
        width: 100%;
        margin-top: 32px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            margin-top: 48px;
            padding: 0 16px;

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            width: 33.33%;
            margin-top: 64px;

            &:nth-child(3) {
                margin-top: 0;
            }
        }
        
        .product-card {
            height: 100%;
        }
    }
}

.product-carousel-container {
    position: relative;
        
    .product-card {
        height: 100%;
    }

    .swiper-wrapper {

        .swiper-slide {
            height: auto;
        }
    }

    .product-carousel-prev,
    .product-carousel-next {
        position: absolute;
        z-index: 20;
        top: -1px;
        bottom: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10vw;
        min-width: 24px;
        padding: 0;
        color: $white;
        border: none;
        background-color: transparent;
        opacity: 1;
        pointer-events: auto;
        transition: opacity 0.3s;

        &.swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
        }

        .product-carousel-nav-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: $darkgrey;

            @include media-breakpoint-up(md) {
                width: 28px;
                height: 28px;
            }
        }
    }

    .product-carousel-prev {
        left: -5vw;
        background: linear-gradient(90deg, rgba(249,250,251,1) 0%, rgba(249,250,251,0) 100%);
    }

    .product-carousel-next {
        right: -5vw;
        background: linear-gradient(90deg, rgba(249,250,251,0) 0%, rgba(249,250,251,1) 100%);
    }
}

.heading-md + .product-carousel-container,
.product-carousel-container + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}

.product-hero {

    &.barclaycard-hero,
    &.tyl-hero {
        background-image: url('../img/plugins/barclaycard-hero-bg.jpg');
    }

    &.lloyds-hero {
        background-image: url('../img/plugins/lloyds-hero-bg.jpg');
    }

    &.authipay-hero,
    &.twocheckout-hero {
        background-image: url('../img/plugins/authipay-hero-bg.jpg');
    }

    &.ingenico-hero {
        background-image: url('../img/plugins/ingenico-hero-bg.jpg');
    }

    &.fiserv-hero {
        background-image: url('../img/plugins/fiserv-hero-bg.jpg');

        .rating {

            ion-icon {

                &.filled {
                    color: #F5FF00;
                }
            }
        }
    }

    &.payeezy-hero,
    &.postfinance-hero {
        background-image: url('../img/plugins/payeezy-hero-bg.jpg');

        .richtext {

            p {
                color: $white;
            }
        }

        .product-hero-note {
            color: $white;
        }
    }

    &.opayo-hero {
        background-image: url('../img/plugins/opayo-hero-bg.jpg');
    }

    &.viveum-hero {
        background-image: url('../img/plugins/viveum-hero-bg.jpg');
    }

    &.adyen-hero {
        background-image: url('../img/plugins/adyen-hero-bg.jpg');
    }

    .hero-container {
        padding: 64px 0;

        @include media-breakpoint-up(md) {
            padding: 80px 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 96px 0;
        }
    }

    .rating {
        display: flex;
        min-height: 16px;

        ion-icon {
            margin: 0 2px;
            color: rgba(255,255,255,0.4);

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.filled {
                color: $pink;
            }
        }
    }

    .product-hero-heading {
        max-width: 17.5em;
        margin: 0 auto 0.5em auto;
        font-weight: 700;
        font-size: 24px;

        @include media-breakpoint-up(md) {
            font-size: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 0 0.5em 0;
        }

        @include media-breakpoint-up(xl) {
            font-size: 40px;
        }
    }

    .richtext {
        max-width: 30em;
        margin-left: auto;
        margin-right: auto;

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .product-hero-details {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 14px;

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }

        .reviews {
            text-align: right;
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid rgba(255,255,255,0.1);

            @include media-breakpoint-up(lg) {
                text-align: left;
            }

            .number {
                font-weight: 500;
            }
        }

        .updates {
            text-align: left;

            .version {
                font-weight: 500;
            }

            .latest {
                color: rgba(255,255,255,0.8);
            }
        }

        + .product-hero-actions {
            margin-top: 24px;
        }
    }

    * + .product-hero-details {
        margin-top: 16px;
    }

    .product-hero-actions {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -4px;

        @include media-breakpoint-up(lg) {
            justify-content: flex-start;
        }

        > * {
            margin: 4px;
        }

        .dropdown {
            position: relative;
            flex: 1;

            @include media-breakpoint-up(sm) {
                flex: initial;
            }

            .dropdown-toggle {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 40px;
                padding: 0 16px;
                font-weight: 400;
                color: $darkgrey;
                border-radius: 8px;
                border: none;
                background: $white;
                box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.16);

                @include media-breakpoint-up(sm) {
                    width: auto;
                    min-width: 240px;
                }

                @include media-breakpoint-up(md) {
                    height: 44px;
                }

                @include media-breakpoint-up(xl) {
                    height: 48px;
                }

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                min-width: 100%;
                border-radius: 10px;
                border: none;
                background: $white;
                box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.16);

                .dropdown-item {
                    padding: 8px 16px;
                    color: $darkgrey;
                    transition: color 0.2s, background-color 0.2s;

                    .price {
                        transition: color 0.2s;
                    }

                    &:hover {
                        color: $black;
                        background: $lightestgrey;

                        .price {
                            color: $pink;
                        }
                    }
                }
            }

            .price {
                font-weight: 500;
                color: $black;
            }
        }

        .btn {
            box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.16);
        }
    }

    .product-hero-note {
        max-width: 30em;
        margin-left: auto;
        margin-right: auto;
        font-size: 12px;
        line-height: 1.5;
        color: rgba(255,255,255,0.4);

        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    * + .product-hero-note {
        margin-top: 1em;
    }

    .product-hero-left,
    .product-hero-right {

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }
    }

    .product-hero-right {
        align-items: center;
        width: 100%;
        margin: 64px auto 0 auto;

        @include media-breakpoint-up(sm) {
            width: 80%;
        }

        @include media-breakpoint-up(lg) {
            margin: auto;
        }

        .product-hero-image {
            width: 100%;
            padding-bottom: 67%;
            border-radius: 10px;
            background-color: $white;
            background-position: center;
            background-size: cover;
            box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.16);
        }

        .product-hero-review {
            display: flex;
            padding: 16px;
            border-radius: 10px;
            border: 1px solid rgba(255,255,255,0.1);
            background-color: rgba(255, 255, 255, 0.25);

            @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
                color: $white;
                backdrop-filter: blur(10px);
                background-color: rgba(255, 255, 255, 0.1);
            }

            .product-hero-review-image {
                flex-shrink: 0;
                width: 48px;
                height: 48px;
                margin-right: 16px;
                border-radius: 50%;
                background-color: $mediumgrey;
                background-position: center;
                background-size: cover;
                box-shadow: inset 0px 0px 0px 1px rgba(255,255,255,0.1), 0px 1px 2px 0px rgba(0,0,0,0.16);
            }

            .product-hero-review-content {
                font-size: 14px;

                .text {

                    .name {
                        font-weight: 600;

                        &::before {
                            content: '•';
                            margin: 0 0.25em;
                            color: rgba(255,255,255,0.4);
                        }
                    }
                }

                .rating {
                    margin-top: 12px;

                    ion-icon {
                        color: rgba(255,255,255,0.4);
            
                        &.filled {
                            color: $white;
                        }
                    }
                }
            }
        }

        .product-hero-image + .product-hero-review {
            margin-top: 8px;
        }
    }
}

.product-menu {
    position: sticky;
    z-index: 300;
    top: 56px;
    background: $white;
    box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.06);

    @include media-breakpoint-up(md) {
        top: 64px;
    }

    @include media-breakpoint-up(lg) {
        top: 72px;
    }

    .product-menu-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        .product-menu-nav {
            display: none;
            margin: 0;
            padding: 0;
            list-style: none;
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                display: flex;
            }

            li {
                margin: 0 1em;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    height: 72px;
                    color: $black;

                    &.product-menu-nav-item {

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 2px;
                            background: $black;
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        }

                        &.active {

                            &::after {
                                transform: scaleX(1);
                                transform-origin: left;
                            }
                        }
                    }
                }
            }
        }

        .product-menu-actions {
            display: flex;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: auto;
            }

            .product-menu-dropdown {
                position: relative;
                flex: 1;

                @include media-breakpoint-up(lg) {
                    flex: initial;
                }

                .dropdown-toggle {
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 40px;
                    padding: 0 16px;
                    font-weight: 400;
                    color: $darkgrey;
                    border-radius: 8px;
                    border: 1px solid rgba(0,0,0,0.1);
                    background: $lightestgrey;
                    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.02);

                    @include media-breakpoint-up(lg) {
                        width: auto;
                        min-width: 200px;
                    }

                    &::after {
                        display: none;
                    }
                }

                .dropdown-menu {
                    min-width: 100%;
                    border-radius: 10px;
                    border: 1px solid rgba(0,0,0,0.1);

                    .dropdown-item {
                        padding: 8px 16px;
                        color: $darkgrey;
                        transition: color 0.2s, background-color 0.2s;
    
                        .price {
                            transition: color 0.2s;
                        }
    
                        &:hover {
                            color: $black;
                            background: $lightestgrey;
    
                            .price {
                                color: $pink;
                            }
                        }
                    }
                }

                .price {
                    font-weight: 500;
                    color: $black;
                }

                + .btn {
                    margin-left: 8px;
                }
            }

            .btn {
                height: 40px;
                padding: 0 20px;
            }
        }
    }
}

.faq-accordion {
    counter-reset: accordion-counter;
    
    .accordion-item {
        border-bottom: 1px solid rgba(0,0,0,0.06);
        counter-increment: accordion-counter;

        &:last-child {
            border: none;
        }

        .accordion-item-header {

            button {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 1.25em 0;
                color: $black;
                border: none;
                background: none;

                @include media-breakpoint-up(md) {
                    padding: 1.5em 0;
                }

                .text {
                    position: relative;
                    padding-left: 40px;
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;

                    @include media-breakpoint-up(md) {
                        font-size: 18px;
                    }

                    &::before {
                        content: counter(accordion-counter);
                        position: absolute;
                        left: 0;
                        top: 0.1em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 24px;
                        height: 24px;
                        margin-right: 16px;
                        font-weight: 500;
                        font-size: 10px;
                        color: $pink;
                        border-radius: 50%;
                        background: rgba(209,3,226,0.08);
                    }
                }

                ion-icon {
                    margin: 0.3em 0 0 1.5em;
                }

                &[aria-expanded="true"] {

                    ion-icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .accordion-item-body {
            padding: 0 0 32px 40px;
        }
    }
}

.heading-md + .faq-accordion {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}

.product-intro-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;

    @include media-breakpoint-up(lg) {
        height: 100%;
        margin-bottom: 0;
    }

    .product-intro-image-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 80%;

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &::before {
            right: 0;
            top: 0;
            width: 80px;
            height: 80px;
            background-image: url('../img/interface/product-intro-card.svg');
            transform: translate(40%,-60%);

            @include media-breakpoint-up(md) {
                width: 100px;
                height: 100px;
            }

            @include media-breakpoint-up(xl) {
                width: 120px;
                height: 120px;
            }
        }

        &::after {
            left: 0;
            bottom: 0;
            width: 100px;
            height: 100px;
            background-image: url('../img/interface/product-intro-trolley.svg');
            transform: translate(-50%,60%);

            @include media-breakpoint-up(md) {
                width: 125px;
                height: 125px;
            }

            @include media-breakpoint-up(xl) {
                width: 150px;
                height: 150px;
            }
        }

        .window {
            position: relative;
            z-index: 1;
            width: 100%;
            overflow: hidden;
            border-radius: 10px;
            border: 1px solid $darkgrey;
            
            .top {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 5px;
                background: $darkgrey;

                span {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin-right: 2px;
                    border-radius: 50%;
                    background: $mediumgrey;
                }
            }

            img {
                display: block;
                width: 100%;
            }
        }

        .btn {
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 100%;
            white-space: nowrap;
            transform: translate(-50%,-50%);
        }
    }
}

.product-screenshot-gallery-container {
    position: relative;
    overflow: hidden;
    padding: 0 10%;

    .product-screenshot-gallery {
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            z-index: 10;
            top: -1px;
            bottom: -1px;
            width: 12.5%;
        }
    
        &::before {
            left: 0;
            background: linear-gradient(90deg, rgba(249,250,251,1) 0%, rgba(249,250,251,0) 100%);
            transform: translateX(-100%) translateZ(0);
        }
    
        &::after {
            right: 0;
            background: linear-gradient(90deg, rgba(249,250,251,0) 0%, rgba(249,250,251,1) 100%);
            transform: translateX(100%) translateZ(0);
        }

        .product-screenshot-gallery-item {
            overflow: hidden;
            border-radius: 10px;
            border: 1px solid $darkgrey;
            opacity: 0.2;
            transition: opacity 0.6s;
            
            .top {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 5px;
                background: $darkgrey;

                @include media-breakpoint-up(lg) {
                    padding: 9px;
                }

                span {
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin-right: 2px;
                    border-radius: 50%;
                    background: $mediumgrey;

                    @include media-breakpoint-up(lg) {
                        width: 6px;
                        height: 6px;
                        margin-right: 4px;
                    }
                }
            }

            a {
                display: block;
            }

            img {
                display: block;
                width: 100%;
            }
        }

        .swiper-wrapper {

            .swiper-slide {
                transform: translate3d(0,0,0);

                &.swiper-slide-active {

                    .product-screenshot-gallery-item {
                        opacity: 1;
                    }
                }
            }
        }

        .product-screenshot-gallery-prev,
        .product-screenshot-gallery-next {
            position: absolute;
            z-index: 20;
            top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            min-width: 24px;
            padding: 0;
            color: $white;
            border-radius: 50%;
            border: none;
            background: $darkgrey;
            opacity: 1;
            transition: opacity 0.3s;

            @include media-breakpoint-up(md) {
                width: 28px;
                height: 28px;
            }

            &.swiper-button-disabled {
                opacity: 0;
            }
        }

        .product-screenshot-gallery-prev {
            left: 0;
            transform: translate(-50%,-50%);
        }

        .product-screenshot-gallery-next {
            right: 0;
            transform: translate(50%,-50%);
        }
    }

    .product-screenshot-gallery-footer {
        display: flex;

        .product-screenshot-gallery-pagination {
            position: relative;
            left: auto;
            bottom: auto;
            display: inline-flex;
            width: 10%;
        }

        .product-screenshot-gallery-captions {
            width: 100%;
            margin-top: 1em;
            font-size: 14px;
            text-align: center;

            @include media-breakpoint-up(md) {
                margin-top: 1.5em;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 2em;
            }

            .swiper-wrapper {
                counter-reset: caption-counter;
                
                .swiper-slide {
                    width: auto;
                    counter-increment: caption-counter;

                    .product-screenshot-gallery-captions-item {
                        color: $darkgrey;

                        &::before {
                            content: counter(caption-counter) '/' attr(data-total-slides);
                            margin-right: 0.75em;
                            font-weight: 500;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

* + .product-screenshot-gallery-container,
.product-screenshot-gallery-container + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 64px;
    }
}

.changelog-modal-trigger {
    cursor: pointer;
}

.changelog-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 5vw;
    background: rgba(38,35,53,0.75);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s, visibility 0.3s;

    .changelog-modal-container {
        position: relative;
        width: 100%;
        max-width: 560px;
        max-height: calc(100vh - 10vw);
        overflow-y: auto;
        margin: auto;
        padding: 24px;
        border-radius: 10px;
        background: $white;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);

        @include media-breakpoint-up(md) {
            padding: 32px;
        }

        .changelog-modal-close {
            position: absolute;
            z-index: 1;
            right: 24px;
            top: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            padding: 0;
            color: $black;
            border-radius: 50%;
            border: none;
            background: $lightestgrey;

            @include media-breakpoint-up(md) {
                right: 32px;
                top: 32px;
            }

            .material-icons {
                font-size: 20px;
            }
        }

        .changelog-modal-section {
            overflow: hidden;
            font-size: 14px;
            border-radius: 8px;
            border: 1px solid rgba(0,0,0,0.12);

            .changelog-modal-section-header,
            .changelog-modal-section-row {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding: 8px 16px;

                &:last-child {
                    border: none;
                }
            }

            .changelog-modal-section-header {
                justify-content: space-between;
                color: $black;
                border-bottom: 1px solid rgba(0,0,0,0.12);

                .version {
                    font-weight: 700;
                }
            }

            .changelog-modal-section-row {
                border-bottom: 1px solid rgba(0,0,0,0.06);
                background: $lightestgrey;

                .type {
                    flex-shrink: 0;
                    width: 120px;
                    font-weight: 500;
                }

                .description {
                    flex: 1;
                    color: $darkgrey;
                }
            }

            + .changelog-modal-section {
                margin-top: 16px;
            }
        }

        .heading-md + .changelog-modal-section {
            margin-top: 24px;
        }
    }

    &.changelog-modal-is-open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}