.icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    overflow: hidden;
    color: $pink;
    border-radius: 16px;
    background: #EEE;

    &::after {
        display: none;
    }

    ion-icon {
        position: relative;
        z-index: 1;
        font-size: 28px;
    }
    
    @supports (mix-blend-mode: screen) {
        color: black;

        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            background: linear-gradient(120deg, rgba(255,0,140,1) 40%, rgba(142,0,189,1) 70%);
            mix-blend-mode: screen;
        }
    }

    &.icon-pink {
        color: $white;
        background: linear-gradient(120deg, rgba(255,0,140,1) 0%, rgba(142,0,189,1) 100%);

        &::after {
            display: none;
        }
    }

    + .heading-xs,
    + .heading-sm,
    + .heading-md,
    + .heading-lg,
    + .heading-xl {
        margin-top: 1em;

        @include media-breakpoint-up(md) {
            margin-top: 1.25em;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 1.5em;
        }
    }
}

.bg-lightestgrey {

    .icon {
        background: $white;
    }
}

.icon-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin: 0 -16px;
        text-align: left;
    }

    li {
        width: 100%;
        margin-top: 16px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            width: 50%;
            margin-top: 24px;
            padding: 0 12px;

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 33.33%;

            &:nth-child(3) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-top: 32px;
            padding: 0 16px;
        }

        .icon-list-item {
            width: 100%;
            height: 100%;
            padding: 32px;
            border-radius: 10px;
            border: 1px solid rgba(0,0,0,0.03);
            background: $lightestgrey;

            .icon {
                background: $white;
            }
        }
    }
}

.featured-icon-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        width: 100%;
        padding: 32px 0;
        border-bottom: 1px solid rgba(0,0,0,0.06);

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border: none;
        }

        @include media-breakpoint-up(lg) {
            width: 33.33%;
            padding: 0 32px;
            border-right: 1px solid rgba(0,0,0,0.06);
            border-bottom: none;
        }

        .featured-icon-list-item {
            width: 100%;
            max-width: 17.5rem;
            margin: 0 auto;
        }
    }
}