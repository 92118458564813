.glightbox-container {

    .goverlay {
        background: rgba(38,35,53,0.75);
    }

    .gslide-media {
        box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
    }

    .gprev,
    .gnext,
    .gclose {
        width: 32px;
        height: 32px;
        padding: 0;
        color: $white;
        border-radius: 50%;
        border: none;
        background: rgba(38,35,53,0.75) !important;
        transition: background-color 0.2s;

        @include media-breakpoint-up(xl) {
            width: 40px;
            height: 40px;
        }
        
        .material-icons {
            font-size: 20px;
        }

        &:hover {
            color: $white;
            background: rgba(38,35,53,1) !important;
        }
    }

    .gprev,
    .gnext {
        top: 50%;
        transform: translateY(-50%);
    }

    .gprev {
        left: 16px;
    }

    .gnext {
        right: 16px;
    }

    .gclose {
        right: 16px;
        top: 16px;
    }
}