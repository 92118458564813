.team-listings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(sm) {
        margin: 0 -12px;
    }

    @include media-breakpoint-up(xl) {
        margin: 0 -16px;
    }

    li {
        width: 100%;
        margin-top: 24px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @include media-breakpoint-up(sm) {
            width: 50%;
            padding: 0 12px;

            &:nth-child(2) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 33.33%;

            &:nth-child(3) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-top: 32px;
            padding: 0 16px;
        }

        .team-listings-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            padding: 32px;
            text-align: center;
            border-radius: 10px;
            border: 1px solid rgba(0,0,0,0.03);
            background: $lightestgrey;

            .image {
                width: 160px;
                height: 160px;
                border-radius: 50%;
                background-color: $lightgrey;
                background-position: center;
                background-size: cover;
                box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.06);


                @include media-breakpoint-only(sm) {
                    width: 120px;
                    height: 120px;
                }
            }

            .name {
                margin-top: 1em;
            }

            .role {
                margin: 0;
                font-weight: 300;
                font-size: 16px;
                color: $mediumgrey;
            }

            .link {
                margin-top: 1em;
                font-weight: 500;
                text-decoration: underline;
                color: $pink;
            }
        }
    }
}

* + .team-listings,
.team-listings + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 63px;
    }
}