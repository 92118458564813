.support-listings {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
        margin: 0 -12px;
    }

    @include media-breakpoint-up(xl) {
        margin: 0 -16px;
    }

    li {
        width: 100%;
        margin-top: 16px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            margin-top: 24px;
        }

        @include media-breakpoint-up(lg) {
            width: 33.33%;
            padding: 0 12px;

            &:nth-child(2),
            &:nth-child(3) {
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            margin-top: 32px;
            padding: 0 16px;
        }

        .support-listings-item {
            display: block;
            height: 100%;
            padding: 32px;
            text-align: center;
            border-radius: 10px;
            border: 2px solid $white;
            background: $white;
            box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
            transform: translateY(0);
            transition: border 0.2s, background-color 0.2s, box-shadow 0.2s, transform 0.2s;

            .icon {
                background: $lightestgrey;
            }

            .heading-sm {
                color: $black;
            }

            @media (hover: hover) and (pointer: fine) {

                &:hover {
                    box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
                    transform: translateY(-4px);
                }
            }

            &.active {
                border: 2px solid $pink;
                background: #FEF9FC;

                .icon {
                    background: $white;
                }
            }
        }
    }
}

* + .support-listings,
.support-listings + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 63px;
    }
}

.support-question {

    .heading-xs {
        margin: 0;

        + .richtext-sm {
            margin-top: 0.25em;
        }

        + .dropdown,
        + input {
            margin-top: 12px;
        }
    }

    .richtext-sm {

        + .dropdown,
        + input {
            margin-top: 12px;
        }
    }

    .dropdown {
        width: 100%;

        .dropdown-toggle {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 48px;
            padding: 0 20px;
            font-weight: 500;
            color: $black;
            border-radius: 10px;
            border: 1px solid rgba(0,0,0,0.12);
            background: $white;
            box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.02);

            &::after {
                display: none;
            }

            &[aria-expanded="true"] {
                border-radius: 10px 10px 0 0;
                border-bottom: 1px solid rgba(0,0,0,0.06);

                ion-icon {
                    transform: rotate(180deg);
                }
            }
        }

        .dropdown-menu {
            width: 100%;
            margin: 0;
            padding: 8px 0;
            border-radius: 0 0 10px 10px;
            border: 1px solid rgba(0,0,0,0.12);
            border-top: none;

            .dropdown-item {
                padding: 8px 20px;
                color: $darkgrey;
                transition: color 0.2s, background-color 0.2s;

                &:hover {
                    color: $black;
                    background: $lightestgrey;
                }
            }
        }
    }

    input {
        display: block;
        width: 100%;
        height: 48px;
        margin: 0 0 12px 0;
        padding: 0 20px;
        font-weight: 300;
        color: $darkgrey;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        background: $white;
        outline: none;
        appearance: none;
        transition: color 0.2s, border 0.2s;

        @include media-breakpoint-up(md) {
            margin: 0 0 14px 0;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 0 16px 0;
        }

        &:focus {
            color: $black;
            border: 1px solid $pink;
            background: $white;
        }
    }

    + .support-question {
        margin-top: 24px;

        @include media-breakpoint-up(md) {
            margin-top: 28px;
        }
    
        @include media-breakpoint-up(xl) {
            margin-top: 32px;
        }
    }
}

.support-answers {

    .support-answers-item {
        display: block;
        margin-top: 16px;
        padding: 32px;
        border-radius: 10px;
        background: $white;
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
        transform: translateY(0);
        transition: box-shadow 0.2s, transform 0.2s;

        &:first-child {
            margin-top: 0;
        }

        .heading-xs {
            color: $black;
        }

        .snippet {
            font-size: 14px;
            color: $darkgrey;

            &::after {
                content: 'View article';
                margin-left: 3px;
                font-weight: 500;
                text-decoration: underline;
                color: $pink;
            }
        }

        @media (hover: hover) and (pointer: fine) {

            &:hover {
                box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
                transform: translateY(-4px);
            }
        }
    }
}

.heading-sm + .support-answers {
    margin-top: 16px;

    @include media-breakpoint-up(md) {
        margin-top: 24px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 32px;
    }
}