.reviews-carousel-container {
    position: relative;

    .reviews-carousel {
        position: relative;

        @include media-breakpoint-up(xl) {
            overflow: hidden;
        }

        .swiper-wrapper {

            .swiper-slide {
                height: auto;
            }
        }

        .reviews-carousel-item {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 32px 32px 24px 32px;
            border-radius: 10px;
            background: $white;

            .reviews-carousel-item-content {
                flex: 1;
                
                .heading-sm {
                    color: $black;
                }

                .reviews-carousel-item-body {
                    position: relative;
                    max-height: 8.5em;
                    overflow: hidden;
                    transition: max-height 0.6s;

                    .richtext {

                        p {
                            color: $darkgrey;
                        }
                    }

                    &.has-expanded-content {

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 1.7em;
                            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%);
                            opacity: 1;
                            transition: opacity 0.6s;
                        }

                        &.is-expanded {
                            max-height: 50em;
    
                            &::after {
                                opacity: 0;
                            }
                        }
                    }
                }

                .reviews-carousel-item-expand-btn {
                    position: relative;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    margin-top: 8px;
                    padding: 0;
                    color: $pink;
                    border-radius: 50%;
                    border: 1px solid $pink;
                    background: transparent;
                    transition: color 0.2s, background-color 0.2s;
                    
                    &::before,
                    &::after {
                        position: absolute;
                        font-family: 'Material Icons';
                        font-size: 16px;
                    }
                    
                    &::before {
                        content: 'expand_more';
                        display: inline-block;
                    }
                    
                    &::after {
                        content: 'expand_less';
                        display: none;
                    }

                    &:hover {
                        color: $white;
                        background: $pink;
                    }

                    &.is-required {
                        display: inline-flex;
                    }

                    &.is-expanded {
                        display: inline-flex;
                    
                        &::before {
                            display: none;
                        }
                        
                        &::after {
                            display: inline-block;
                        }
                    }
                }
            }

            .reviews-carousel-item-footer {
                margin-top: 32px;
                padding-top: 16px;
                border-top: 1px solid rgba(0,0,0,0.06);

                .rating {
                    color: $lightgrey;
                    
                    .filled {
                        color: $pink;
                    }
                }

                .ref {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    font-size: 14px;

                    li {

                        &::after {
                            content: '•';
                            margin: 0 0.5em;
                            color: $lightgrey;
                        }

                        &:last-child {

                            &::after {
                                display: none;
                            }
                        }

                        &.name {
                            font-weight: 500;
                            color: $black;
                        }

                        &.link {
                            color: $darkgrey;

                            a {
                                text-decoration: underline;
                                color: $darkgrey;
                            }
                        }
                    }
                }
            }
        }

        .reviews-carousel-pagination-container {
            position: relative;
            width: 100%;
            height: 6px;
            margin-top: 16px;

            @include media-breakpoint-up(md) {
                margin-top: 24px;
            }

            @include media-breakpoint-up(md) {
                margin-top: 32px;
            }

            .reviews-carousel-pagination {
                position: absolute;
                bottom: 0;

                .swiper-pagination-bullet {
                    width: 6px;
                    height: 6px;
                    margin: 0 6px;
                    border-radius: 50%;
                    background: $white;
                    opacity: 0.25;
                    transition: left 0.2s, opacity 0.2s, transform 0.2s;

                    &.swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .reviews-carousel-prev,
    .reviews-carousel-next {
        position: absolute;
        z-index: 20;
        top: -1px;
        bottom: -1px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10vw;
        margin: 0;
        padding: 0 0 22px 0;
        color: $white;
        border: none;
        background-color: transparent;
        opacity: 1;
        pointer-events: auto;
        transition: opacity 0.3s;

        @include media-breakpoint-up(md) {
            padding: 0 0 30px 0;
        }

        @include media-breakpoint-up(xl) {
            top: 50%;
            bottom: auto;
            width: auto;
            margin: -30px 0 0 0;
            padding: 0;
        }

        &.swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
        }

        .reviews-carousel-nav-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: $darkgrey;
        }
    }

    .reviews-carousel-prev {
        left: -5vw;
        background: linear-gradient(90deg, rgba(49,46,63,1) 0%, rgba(49,46,63,0) 100%);

        @include media-breakpoint-up(xl) {
            left: -44px;
            background: none;
        }
    }

    .reviews-carousel-next {
        right: -5vw;
        background: linear-gradient(270deg, rgba(49,46,63,1) 0%, rgba(49,46,63,0) 100%);

        @include media-breakpoint-up(xl) {
            right: -44px;
            background: none;
        }
    }
}

* + .reviews-carousel-container,
.reviews-carousel-container + * {
    margin-top: 32px;

    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 63px;
    }
}

.review-modal-trigger {
    cursor: pointer;
}

.review-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 5vw;
    background: rgba(38,35,53,0.75);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s, visibility 0.3s;

    .review-modal-container {
        position: relative;
        width: 100%;
        max-width: 560px;
        max-height: calc(100vh - 10vw);
        overflow-y: auto;
        margin: auto;
        padding: 24px;
        border-radius: 10px;
        background: $white;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);

        @include media-breakpoint-up(md) {
            padding: 32px;
        }

        .review-modal-close {
            position: absolute;
            z-index: 1;
            right: 12px;
            top: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            padding: 0;
            color: $black;
            border-radius: 50%;
            border: none;
            background: $lightestgrey;

            @include media-breakpoint-up(md) {
                right: 16px;
                top: 16px;
            }

            .material-icons {
                font-size: 20px;
            }
        }

        .richtext {

            p {
                max-width: 25em;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .form {

            label,
            legend {
                font-size: 12px;

                + input,
                + textarea {
                    margin-top: 6px;
                }
            }
        }

        * + .form {
            margin-top: 32px;
        }
    }

    &.review-modal-is-open {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}