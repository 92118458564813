// Bootstrap
@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/transitions";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group"; // Requires forms
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar"; // Requires nav
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
// @import "bootstrap/scss/modal"; // Requires transitions
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/print";

// Colours
$white: #FFF;
$black: #262335;
$pink: #E2037D;
$green: #23CE6B;
$darkestgrey: #312E3F;
$darkgrey: #5C5A68;
$mediumgrey: #93919A;
$lightgrey: #C9C8CD;
$lightestgrey: #F9FAFB;

// Custom styles
@import "globals/index";
@import "templates/index";