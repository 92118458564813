html, body { font-family: 'Inter', sans-serif; font-weight: 300; color: $black; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; &.no-scroll { overflow: hidden; } }
a, button { text-decoration: none; outline: none; &:hover { text-decoration: none; } }
.container { max-width: 1200px; padding-left: 0; padding-right: 0; }

@import "header";
@import "footer";

@import "utilities";

@import "breadcrumbs";
@import "buttons";
@import "glightbox";
@import "forms";
@import "hero";
@import "icons";
@import "layout";
@import "menu";
@import "reviews";
@import "richtext";